<template>
   <v-layout row class="contactus-ovverly">

      <div style="width:105%;">

         <v-layout row wrap justify-center style="padding-top:5%">
            <v-flex xs12 md3 sm3>
               <v-layout column="" wrap class="contactus-right">
                  <v-flex>
                     <span>اتصل بنا</span>
                     <br>
                     <br><br>
                     <v-layout row wrap>
                        <v-icon dark>fas fa-map-marker-alt</v-icon>
                        <span style="padding-right:10px">العراق- بغداد</span>
                     </v-layout>
<!-- 
                     <br><br>
                     <v-layout row wrap>
                        <v-icon dark>fas fa-envelope</v-icon>
                        <span style="padding-right:10px">example@exampl.df.com </span>
                     </v-layout>


                     <br><br>
                     
                  <v-layout row wrap>
                        <v-icon dark>fab fa-facebook-square</v-icon>
                        <span style="padding-right:10px">example@facebook.com </span>

                     </v-layout> -->





                     <br><br>
                     <v-layout row wrap>
                        <v-icon dark>fas fa-mobile-alt</v-icon>
                        <span style="padding-right:10px">+9647703406477</span>

                     </v-layout>
                     <br>
                     <!-- <v-layout row wrap pa-5>
                        <v-flex xs2>
                           <v-icon dark>fab fa-twitter-square</v-icon>
                        </v-flex>
                        <v-flex xs2>
                           <v-icon dark>fab fa-instagram</v-icon>
                        </v-flex>
                        <v-flex xs2>
                           <v-icon dark>fab fa-linkedin</v-icon>
                        </v-flex>

                     </v-layout> -->

                  </v-flex>
               </v-layout>
            </v-flex>



            <v-flex xs12 md3 sm3>
               <v-layout column="" wrap class="contactus-left">

                  <span> ابقى على تواصل</span>
                  <br>
                  <br>

                  <v-form ref="form" v-model="valid">

                     <v-text-field color="" v-model="editedItem.name" label="ادخل اسمك" background-color="#D0DFE633"
                        solo>
                     </v-text-field>
                     <v-text-field label="البريد الالكتروني" :rules="emailRules" v-model="editedItem.email"
                        background-color="#D0DFE633" solo>
                     </v-text-field>
                     <v-textarea background-color="#D0DFE633" :rules="messageRules" v-model="editedItem.message" solo
                        label="الوصف"></v-textarea>

                     <VBtn @click="submit()" style="width:100%" color="#242424" dark>
                        <v-icon right="">fas fa-paper-plane</v-icon>
                        ارسال
                     </VBtn>
                  </v-form>


               </v-layout>
            </v-flex>

         </v-layout>
         <v-row class="mb-12">
         </v-row>
      </div>


      <!-- </div> -->
   </v-layout>




</template>





<script>
   const axios = require('axios');
   export default {
      data: () => ({



         token: localStorage.getItem('access_token_user'),


         dialog: false,
         messages: [],
         // editedIndex: -1,
         valid: false,
         loding_accept: false,
         marvelHeroes: [],
         loding_cancel: false,

         emailRules: [


            // (v) => !!v || 'يجب ادخال الايميل',
            (v) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || v.length < 1) ||
            'ألايميل غير صحيح',


         ],


         messageRules: [
            (v) => !!v || 'يجب ادخال نص الرساله',

         ],



         editedItem: {
            name: '',
            email: '',
            message: ''
         },

         defaultItem: {
            name: '',
            calories: 0,
            fat: 0,
            carbs: 0,
            protein: 0,
         },




      }),



      methods: {


         submit() {


            if (this.$refs.form.validate()) {

               if (this.editedItem.email) {

                  var data = {

                     name: this.editedItem.name,
                     email: this.editedItem.email,
                     message: this.editedItem.message,

                  };
               } else {

                  data = {

                     name: this.editedItem.name,
                     //  email: this.editedItem.email,
                     message: this.editedItem.message,

                  };
               }



               axios({
                     method: 'post',
                     url: "/contactus",
                     data: data
                  })
                  .then(response => {
                     response
                     const Swal = require('sweetalert2')
                      this.$refs.form.reset()

                     Swal.fire({
                        position: 'center',
                        icon: 'fas fa-paper-plane',
                        title: 'شكرا تم الارسال بنجاح',
                        showConfirmButton: false,
                        timer: 1500
                     })

                     // this.editedItem = {

                     // };



                  })
                  .catch(error => {
                     error

                  });

            }


         },
















      },



      created() {


      },
      mounted() {



      },
      computed: {


      },


      watch: {

      },
   }
</script>